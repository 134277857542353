<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout white wrap ma-2>
      <material-UserStoryForm @save="createUserStory($event)" />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserStoryCreateForm',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({}),

  methods: {
    ...mapActions('userStory', ['create_user_stories']),
    ...mapMutations('app', ['showLoader']),

    createUserStory(userStories) {
      this.showLoader();
      this.create_user_stories(userStories)
        .then((response) => {
          console.log('CREATE', response);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text:
              'Création de la User Story#' +
              response.id +
              ' "' +
              response.name +
              '" effectué avec succès',
          });
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Création de la User Story impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },
  },
};
</script>
